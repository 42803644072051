import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import { clamp } from 'lodash'

import { calculateStep, roundValueToStep, getSliderStyles } from './utils'
import * as styles from './SliderInput.module.scss'

const cx = classnames.bind(styles)

const propTypes = {
  /** Makes this control unable to be changed by the user. */
  disabled: PropTypes.bool,
  /** Will be triggered while the value changing. */
  handleChange: PropTypes.func.isRequired,
  /** The maximum value of the slider. */
  maximum: PropTypes.number.isRequired,
  /** The minimum value of the slider. */
  minimum: PropTypes.number.isRequired,
  /**
   * Optional value to be added or subtracted on each step the slider makes.
   * Must be greater than zero, and maximum - minimum should be evenly divisible by the step value.
   * Step size is inferred from the minimum and maximum if not provided.
   */
  step: PropTypes.number,
  /** The type of slider, less than, greater than, etc. Required for value ranges. */
  type: PropTypes.oneOf(['lt', 'lte', 'gt', 'gte', 'between', 'notbetween']),
  /**
   * Current value of the slider.
   * Can either be a single number or an array of two numbers representing a range.
   */
  value(props, propName, componentName) {
    if (props.value === undefined || props.value === null) {
      return new Error(`${componentName}: Value prop must be an array or number`)
    }
    if (['lt', 'lte', 'gt', 'gte'].includes(props.type) && !Number.isFinite(props.value)) {
      return new Error(
        `${componentName}: Value prop must be a finite number for single value sliders`
      )
    }
    if (['between', 'notbetween'].includes(props.type)) {
      if (!Array.isArray(props.value)) {
        return new Error(`${componentName}: Value prop must be an array for range-type sliders`)
      }
      if (props.value.length !== 2) {
        return new Error(`${componentName}: Value prop must be an array with two elements`)
      }
      if (!props.value.every(Number.isFinite)) {
        return new Error(`${componentName}: Value prop must be an array containing only numbers`)
      }
    }
    return undefined
  },
}

function SliderInput({
  disabled = false,
  minimum,
  maximum,
  type = 'lte',
  handleChange,
  value,
  step: stepProp,
}) {
  const leftHandleRef = useRef()
  const rightHandleRef = useRef()
  const trackRef = useRef()
  const [isDragging, setIsDragging] = useState(false)
  const [activeHandle, setActiveHandle] = useState(null)
  const step = useMemo(() => stepProp ?? calculateStep(minimum, maximum), [
    stepProp,
    minimum,
    maximum,
  ])

  // Handle user input, dispatch value changes
  useEffect(() => {
    const releaseHandler = () => {
      setIsDragging(false)
      setActiveHandle(null)
      document.body.style.cursor = ''
    }

    const dragHandler = event => {
      if (disabled) return

      // Must be set globally because user can continue dragging off-track
      document.body.style.cursor = 'grabbing'

      const { width, left } = trackRef.current.getBoundingClientRect()
      const percent = (event.clientX - left) / width
      const rawValue = clamp((maximum - minimum) * percent + minimum, minimum, maximum)
      const newValue = roundValueToStep(rawValue, step, minimum)

      if (['between', 'notbetween'].includes(type)) {
        if (activeHandle === leftHandleRef.current) {
          if (newValue > value[1]) {
            setActiveHandle(rightHandleRef.current)
            handleChange([value[1], newValue])
          } else {
            setActiveHandle(leftHandleRef.current)
            handleChange([newValue, value[1]])
          }
        } else if (activeHandle === rightHandleRef.current) {
          if (newValue <= value[0]) {
            setActiveHandle(leftHandleRef.current)
            handleChange([newValue, value[0]])
          } else {
            setActiveHandle(rightHandleRef.current)
            handleChange([value[0], newValue])
          }
        } else {
          throw new Error('SliderInput: active handle set incorrectly')
        }
      } else {
        handleChange(newValue)
      }
    }

    if (isDragging) {
      document.addEventListener('mousemove', dragHandler)
      document.addEventListener('mouseup', releaseHandler)
    }

    return () => {
      document.removeEventListener('mousemove', dragHandler)
      document.removeEventListener('mouseup', releaseHandler)
      document.body.style.cursor = ''
    }
  }, [
    type,
    isDragging,
    setIsDragging,
    setActiveHandle,
    handleChange,
    minimum,
    maximum,
    step,
    activeHandle,
    disabled,
    value,
  ])

  const {
    leftRailStyles, leftHandleStyles, rightRailStyles, rightHandleStyles,
  } = getSliderStyles(
    value,
    minimum,
    maximum,
    type
  )

  return (
    <div className={styles.slider}>
      <div ref={trackRef} className={cx('track', { disabled })}>
        <div className={styles.rail} style={leftRailStyles} />
        <div className={styles.rail} style={rightRailStyles} />
        <div
          ref={leftHandleRef}
          className={cx('handle', {
            disabled,
            active: !disabled && activeHandle === leftHandleRef.current,
          })}
          style={leftHandleStyles}
          onMouseDown={event => {
            setIsDragging(true)
            setActiveHandle(event.target)
          }}
          tabIndex="0"
          role="slider"
          aria-valuemin={minimum}
          aria-valuemax={maximum}
          aria-valuenow={value}
          aria-disabled={disabled}
        />
        <div
          ref={rightHandleRef}
          className={cx('handle', {
            disabled,
            active: !disabled && activeHandle === rightHandleRef.current,
          })}
          style={rightHandleStyles}
          onMouseDown={event => {
            setIsDragging(true)
            setActiveHandle(event.target)
          }}
          tabIndex="0"
          role="slider"
          aria-valuemin={minimum}
          aria-valuemax={maximum}
          aria-valuenow={value}
          aria-disabled={disabled}
        />
      </div>
      <div className={styles.minMaxContainer}>
        <span className={cx('meta', { disabled })}>
          {minimum}
        </span>
        <span className={cx('meta', { disabled })}>
          {maximum}
        </span>
      </div>
    </div>
  )
}

SliderInput.propTypes = propTypes

export default SliderInput

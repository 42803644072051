import React, { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid'
import { orderBy } from '@progress/kendo-data-query'
import SearchInput from '../FormInput/SearchInput'
import './Table.scss'

const defaultStyle = {
  height: '440px', // virtualization needs set px. height, 440px shows 10 rows (~40px each, 1 column)
  width: '100%',
  overflow: 'auto',
}

const propTypes = {
  /** (cell: Object, props: Object) => Node, where node is the individual cell. Runs for every cell */
  cellRender: PropTypes.func,
  /** optional elements that are displayed next to the filters */
  controls: PropTypes.arrayOf(PropTypes.node),
  /**
    Allows developer using table component to implement their own filter function.
    first param will be the current data, second param is the filter-by string
    must return all or subset of the data array given
  */
  customFilter: PropTypes.func,
  /** Data to be presented in table. */
  data: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ).isRequired,
  /** Adds a filter to the controls section of the table */
  hasFilter: PropTypes.bool,
  /** List of column headers. */
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      /** Label text for the column */
      field: PropTypes.string,
      /** Data that corresponds to this field will be displayed in rows */
      className: PropTypes.string,
      /** Sticky header, defaults to false */
      locked: PropTypes.bool,
      /** in pixels */
      width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      /** Must return a node. replaces the header cell itself */
      headerCell: PropTypes.func,
      cell: PropTypes.func,
      /**
       * Overrides the component that will be rendered as a cell in the row.
       * Prop is the props of the Kendo data cell.
       */
      resizable: PropTypes.bool,
      /** defaults to false */
      minResizableWidth: PropTypes.number,
      /** Only applied if resizable */
    })
  ).isRequired,
  /** Hides the header conditionally */
  hideHeaders: PropTypes.bool,
  /** Initial sort state. */
  initialSort: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      /** Sorting direction */
      dir: PropTypes.oneOf(['asc', 'desc']).isRequired,
    })
  ),
  /** Defaults to true when there are >= 100 data length */
  isVirtualized: PropTypes.bool,
  /** Hides the hover color when hovering rows */
  noHover: PropTypes.bool,
  /** Hides borders on rows */
  noRowBorder: PropTypes.bool,
  /** Controls if the table is resizable by the user. */
  resizable: PropTypes.bool,
  /** False will have no scrollbar, true will be defaulted or virtualized. */
  scrollable: PropTypes.bool,
  /** enables sorting by headers */
  sortable: PropTypes.bool,
  /** DEPRECATED: Styles applied to table. */
  style: PropTypes.shape({ height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }),
  /** How any rows are shown in virtualization. defaults to 20 */
  virtualPageSize: PropTypes.number,
  /** how many pixels tall is each row? defaults to 39 */
  virtualRowHeight: PropTypes.number,
}

function Table({
  data,
  headers,
  scrollable,
  isVirtualized,
  virtualPageSize,
  virtualRowHeight,
  hideHeaders,
  noHover,
  noRowBorder,
  hasFilter,
  customFilter,
  controls,
  style,
  resizable,
  cellRender,
  sortable = false,
  initialSort = [],
}) {
  /**
    State & Helper function setup
  */
  const [filterValue, setFilterValue] = useState('')
  const [skip, setSkip] = useState(0)
  const [sort, setSort] = useState(initialSort)

  const transformedHeaders = useMemo(() => headers.map((header, index) => {
    const headerProps = {
      title: header.label,
      field: header.field,
      className: header.className || '',
      resizable: !!header.resizable,
      locked: !!header.locked,
    }
    if (header.cell) {
      headerProps.cell = header.cell
    }
    if (header.headerCell) {
      headerProps.headerCell = header.headerCell
    }
    if (header.width) {
      headerProps.width = header.width
    }
    if (header.minResizableWidth) {
      headerProps.minResizableWidth = header.minResizableWidth
    }
    if (sortable) {
      const dirClass = { asc: 'ascending', desc: 'descending' }
      if (sort.length && sort[0].field === header.field) {
        headerProps.className = `${headerProps.className} sorted ${dirClass[sort[0].dir]}`
      } else {
        headerProps.className = `${headerProps.className} sortable`
      }
    }

    const customHeaderCell = ({ onClick, title, children }) => (
      <button type="button" className="k-link" onClick={onClick}>
        <span className={headerProps.className}>
          {title}
        </span>
        {children}
      </button>
    )

    return (
      <Column key={`${header.label}-${index}`} headerCell={customHeaderCell} {...headerProps} />
    )
  }), [sort, sortable, headers])

  const filterData = useCallback(
    customFilterFunc => {
      if (typeof customFilterFunc === 'function') {
        return customFilterFunc(data, filterValue)
      }
      const filterBy = filterValue.trim()
      if (!hasFilter || !filterBy.length) return data

      const filtered = data.filter(datum => {
        const keys = Object.keys(datum)
        for (let i = 0; i < keys.length; i++) {
          if (typeof datum[keys[i]] === 'string') {
            const searchFrom = datum[keys[i]].toLowerCase()
            const searchFor = filterBy.toLowerCase()

            if (searchFrom.indexOf(searchFor) > -1) {
              return true
            }
          }
        }
        return false
      })

      return filtered
    },
    [data, filterValue, hasFilter]
  )

  /**
    Props setup & return jsx
  */
  const hasControls = hasFilter || (Array.isArray(controls) && controls.length)

  // virtualization auto-kicks in if data length is greater than 100 unless it is explicitly set
  const shouldVirtualize = typeof isVirtualized === 'boolean' ? isVirtualized : !!(data.length >= 100)

  const appliedStyle = style || {}
  const filteredData = filterData(customFilter)
  const gridProps = {
    data: sortable ? orderBy(filteredData, sort) : filteredData,
    style: {
      ...defaultStyle,
      ...appliedStyle,
    },
    scrollable: typeof scrollable === 'boolean' && !scrollable ? 'none' : 'scrollable',
    className: classnames({
      'no-hover-color': !!noHover,
      'no-row-border': !!noRowBorder,
      'hide-headers': !!hideHeaders,
    }),
    resizable: typeof resizable === 'boolean' ? resizable : false,
  }

  if (typeof cellRender === 'function') {
    gridProps.cellRender = cellRender
  }

  if (shouldVirtualize) {
    const pageSize = typeof virtualPageSize === 'number' ? virtualPageSize : 20
    const rowHeight = typeof virtualRowHeight === 'number' ? virtualRowHeight : 40

    gridProps.rowHeight = rowHeight
    gridProps.pageSize = pageSize
    gridProps.total = filteredData.length
    gridProps.scrollable = 'virtual'
    gridProps.onPageChange = event => {
      setSkip(event.page.skip)
    }
    gridProps.skip = skip
    gridProps.data = gridProps.data.slice(skip, skip + pageSize)
  }
  if (sortable) {
    gridProps.sortable = sortable
    gridProps.sort = sort
    gridProps.onSortChange = e => {
      setSort(e.sort)
    }
  }

  return (
    <section className="kh-table">
      {hasControls && (
        <div className="kh-table-controls-container">
          {hasFilter && (
            <SearchInput
              id="kh-table-control-search-input"
              value={filterValue}
              placeholder="Search"
              hasIcon={true}
              onChange={e => {
                setFilterValue(e.target.value)
              }}
            />
          )}
          {Array.isArray(controls)
            && controls.map((el, index) => (
              <span key={`kh-custom-control-${index}`} className="kh-control-container">
                {el}
              </span>
            ))}
        </div>
      )}
      <Grid {...gridProps}>
        {transformedHeaders}
      </Grid>
    </section>
  )
}

Table.propTypes = propTypes

export default Table

import {
  useCallback,
  useRef,
  useEffect,
  useState,
} from 'react'

const DEFAULT_EXPIRATION_TIME = 31536000 // 1 year

export function Cookie() {
  /**
   * hasCookie
   *  check for presence of a cookie given a key value
   * @param {string} key
   * @return boolean
   * */
  this.hasCookie = key => {
    if (!document.cookie) return false
    return document.cookie.split(';').some(cookie => cookie.trim().startsWith(`${key}=`))
  }
  /**
   * getCookieValue
   *  get the cookie value
   * @param {string} key
   * @return string
   * */
  this.getCookieValue = key => {
    const val = (document.cookie ? document.cookie : '')
      .split(';')
      .find(cookie => cookie.trim().startsWith(`${key}=`))

    return val ? val.split('=')[1] : ''
  }
  /**
   * setCookie
   *  set a new cookie
   *
   * @param key {string} - Required, keyname this cookie will be referenced by
   * @param value {string} - Optional, defaults to 'true'. Stores the string value, stringify POJOs first.
   * @param allowCrossOrigin {boolean} - Allow cross origin cookies, used in Analysis Embed.
   * @param expirationTime {number} - Expiration time in seconds, defaults to one year.
   * @param allowInsecureConnection {boolean} - Optional, defaults to true IN LOCAL, false otherwise.
   *
   * @return void
   * */
  this.setCookie = (
    key,
    value = 'true',
    allowCrossOrigin = false,
    expirationTime = DEFAULT_EXPIRATION_TIME,
    allowInsecureConnection = process.env.BUILD_TARGET === 'local'
  ) => {
    document.cookie = `${key}=${value}; max_age=${expirationTime}; SameSite=${
      allowCrossOrigin ? 'None' : 'Lax'
    };${allowInsecureConnection ? '' : 'Secure;'}`
  }
}

/**
 * useCookie
 * provides hooks around cookies
 * @param {string} key - Identifier of cookie
 * */
function useCookie(key) {
  const cookie = useRef(new Cookie())
  const [value, setValue] = useState(cookie.current.getCookieValue(key))

  const setCookieValue = useCallback(
    (val, allowCrossOrigin = false, expiration = DEFAULT_EXPIRATION_TIME) => {
      cookie.current.setCookie(key, val, allowCrossOrigin, expiration)
      setValue(val)
    },
    [key]
  )

  useEffect(() => {
    setValue(cookie.current.getCookieValue(key))
  }, [cookie, key])

  return [value, setCookieValue]
}

export default useCookie

/** @file An IE11-safe way to derive the composed path off an event */

const getComposedPath = target => {
  const path = []
  let el = target

  while (el) {
    path.push(el)
    if (el.tagName === 'HTML') {
      path.push(document)
      path.push(window)
      return path
    }
    el = el.parentNode
  }

  return undefined
}

export default function safeComposedPath(event) {
  if (event.composedPath) return event.composedPath()
  return getComposedPath(event.target)
}

import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import useCustomValidity from '../useCustomValidity'
import * as styles from '../FormInput.module.scss'

const propTypes = {
  /** Controls if the user can interact with input. */
  disabled: PropTypes.bool,
  /** Marks the input as invalid. The message is not visible, but read by screen readers. */
  errorMessage: PropTypes.string,
  /** HTML ID of the input. */
  id: PropTypes.string,
  /** Controls how long of a value a user can type into the input. */
  maxLength: PropTypes.number,
  /** Controls how short of a value a user can type into the input. */
  minLength: PropTypes.number,
  /** Callback triggered when user changes the value of the input. */
  onChange: PropTypes.func.isRequired,
  /** Optional text shown to user when there is no value in the field. */
  placeholder: PropTypes.string,
  /** Marks the input as required in the context of a form. */
  required: PropTypes.bool,
  /** Controls the size of the input. */
  size: PropTypes.oneOf(['large', 'small']),
  /** Text value of the input. */
  value: PropTypes.string.isRequired,
}

const TextInput = React.forwardRef(({
  className, // eslint-disable-line react/prop-types -- Omit from rest
  errorMessage,
  disabled = false,
  id,
  minLength,
  maxLength,
  onChange,
  placeholder,
  required,
  size = 'large',
  style, // eslint-disable-line react/prop-types -- Omit from rest
  value,
  ...rest
}, forwardedRef) => {
  const localRef = useRef()
  const ref = forwardedRef ?? localRef

  useCustomValidity(ref, errorMessage)

  return (
    <input
      {...rest}
      type="text"
      ref={ref}
      id={id}
      value={value}
      placeholder={placeholder}
      className={classnames(styles.formInput, styles[size])}
      required={required}
      disabled={disabled}
      minLength={minLength}
      maxLength={maxLength}
      onChange={onChange}
    />
  )
})

TextInput.displayName = 'TextInput'
TextInput.propTypes = propTypes

export default TextInput

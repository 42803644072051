import React from 'react'
import useCookie from './useCookie'

/**
 * withCookie
 *  higher order component that provides cookie hooks to React Components
 * usage
 *  when exporting your react component, wrap it with this function along with the cookie name like so:
 *  `export default withCookie($YOUR_REACT_COMPONENT, $COOKIE_NAME)`
 * */
const withCookie = (WrappedComponent, cookieName) => {
  const ComponentWithCookie = props => {
    const [cookieVal, setCookieVal] = useCookie(cookieName)
    return <WrappedComponent {...props} cookieValue={cookieVal} setCookieValue={setCookieVal} />
  }
  return ComponentWithCookie
}

export default withCookie

import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import useCustomValidity from '../useCustomValidity'
import * as styles from '../FormInput.module.scss'

const propTypes = {
  /** Controls if the user can interact with the input. */
  disabled: PropTypes.bool,
  /** Marks the input as invalid. The message is not visible, but read by screen readers. */
  errorMessage: PropTypes.string,
  /** HTML ID of the input. */
  id: PropTypes.string,
  /** Sets the maximum value a user can input. */
  max: PropTypes.number,
  /** Sets the minimum value a user can input. */
  min: PropTypes.number,
  /** Callback triggered when use changes the value of the input. */
  onChange: PropTypes.func.isRequired,
  /** Optional text shown to the user when there is not value in the field. */
  placeholder: PropTypes.string,
  /** Controls the size of the input. */
  size: PropTypes.oneOf(['large', 'small']),
  /** Controls the granularity the value must adhere to. Set to smaller than 1 for decimal values. */
  step: PropTypes.number,
  /** Numeric value of the input. */
  value: PropTypes.number.isRequired,
}

const NumberInput = React.forwardRef(
  (
    {
      className, // eslint-disable-line react/prop-types -- Omit from rest
      disabled = false,
      errorMessage,
      id,
      min,
      max,
      onChange,
      placeholder,
      size = 'large',
      step = 1,
      style, // eslint-disable-line react/prop-types -- Omit from rest
      value,
      ...rest
    },
    forwardedRef
  ) => {
    const localRef = useRef()
    const ref = forwardedRef ?? localRef

    useCustomValidity(ref, errorMessage)

    return (
      <input
        {...rest}
        type="number"
        ref={ref}
        id={id}
        value={value}
        placeholder={placeholder}
        className={classnames(styles.formInput, styles[size])}
        disabled={disabled}
        onChange={onChange}
        min={min}
        max={max}
        step={step}
      />
    )
  }
)

NumberInput.displayName = 'NumberInput'
NumberInput.propTypes = propTypes

export default NumberInput

import React, { useContext, useRef } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import Icon from '../Icon'
import { onEnterKey, onSpaceKey } from '../utils/keyEvents'
import * as styles from './Sidebar.module.scss'

const propTypes = {
  /** Optional, current active tab. Putting in isActive function to Tab would override this */
  activeTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Should be used with Sidebar.Tab items with correct parameters */
  children: PropTypes.node.isRequired,
  /** Function that is called when nav item is clicked. Function will be called with the Tab ID as the parameter. */
  onClick: PropTypes.func.isRequired,
}

const SidebarContext = React.createContext({ onClick: () => {} })

function Sidebar({ children, onClick, activeTab }) {
  // writing to state avoid re-renders caused if this was simply new object instantiation
  const currOnClick = useRef(() => onClick)
  return (
    <SidebarContext.Provider value={{ onClick: currOnClick.current, activeTab }}>
      <ul role="navigation" className={styles.sidebarContainer}>
        {children}
      </ul>
    </SidebarContext.Provider>
  )
}

const tabPropTypes = {
  /** boolean that determines whether nav item is disabled. When disabled, onClick function will not fire. */
  disabled: PropTypes.bool,
  /**
   * Icon to the left of nav item. If undefined, this nav item will be a submenu,
   * appearing with indentation and a smaller text.
   */
  icon: PropTypes.string,
  /** Boolean denoting whether this item is in the active state. Defaults to using tabId === activeTab */
  isActive: PropTypes.bool,
  /** Name which will be rendered as a sidebar nav item */
  name: PropTypes.string.isRequired,
  /** Identifier string that will be passed to the onClick function */
  tabId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

function Tab({
  tabId,
  name,
  isActive,
  disabled = false,
  icon,
}) {
  const { onClick, activeTab } = useContext(SidebarContext)
  const tabIsActive = isActive || tabId === activeTab
  const hasIcon = !!icon

  return (
    <li // eslint-disable-line jsx-a11y/no-noninteractive-element-interactions -- TODO: Nest button inside
      key={tabId}
      disabled={disabled}
      className={cx(
        hasIcon ? styles.sidebarMenu : styles.sidebarSubmenu,
        disabled ? styles.disabled : '',
        tabIsActive ? styles.activeNav : ''
      )}
      onClick={disabled
        ? () => {}
        : () => onClick(tabId)
      }
      onKeyDown={event => {
        if (!disabled && (onEnterKey(event) || onSpaceKey(event))) {
          onClick(tabId)
        }
      }}
    >
      {hasIcon && (
        <Icon icon={icon} size="medium" color="inherit" />
      )}
      <span className={styles.sidebarMenuText}>
        {name}
      </span>
    </li>
  )
}

Sidebar.propTypes = propTypes
Sidebar.Tab = Tab

Tab.propTypes = tabPropTypes

export default Sidebar

// extracted by mini-css-extract-plugin
export var arrow = "Tooltip-module__arrow--3L4z-";
export var baseTiming = "125ms";
export var baseTimingInt = "125";
export var borderRadius = "3px";
export var bottom = "Tooltip-module__bottom--2Yftx";
export var buttonContainer = "Tooltip-module__button-container--h6S7H";
export var charcoal = "#2d2e2e";
export var cloudGrey = "#c8cdcf";
export var dismissButton = "Tooltip-module__dismiss-button--2ZHuP";
export var dismissible = "Tooltip-module__dismissible--wagSy";
export var doveGrey = "#f1f3f5";
export var khBlue = "#04b1e0";
export var khBlueDark = "#0376ae";
export var khBlueLight = "#7de1f4";
export var left = "Tooltip-module__left--1n_Ey";
export var lemon = "#fae436";
export var lemonDark = "#e3cd02";
export var lemonLight = "#fff284";
export var lime = "#05c150";
export var limeDark = "#068230";
export var limeLight = "#65ec83";
export var navy = "#052976";
export var navyDark = "#04173f";
export var navyLight = "#82b1f6";
export var right = "Tooltip-module__right--alTuO";
export var scarlet = "#e9062b";
export var scarletDark = "#9d050e";
export var scarletLight = "#f5726d";
export var standard = "Tooltip-module__standard--2VIzT";
export var stormGrey = "#969b9c";
export var tooltip = "Tooltip-module__tooltip--Aqx86";
export var tooltipEnter = "Tooltip-module__tooltip-enter--1Rw82";
export var tooltipEnterActive = "Tooltip-module__tooltip-enter-active--6PAmd";
export var tooltipEnterDone = "Tooltip-module__tooltip-enter-done--3SfHU";
export var tooltipExit = "Tooltip-module__tooltip-exit--13gUm";
export var tooltipExitActive = "Tooltip-module__tooltip-exit-active--R0NbS";
export var tooltipExitDone = "Tooltip-module__tooltip-exit-done--RO40L";
export var top = "Tooltip-module__top--1Hxmu";
export var violet = "#d401f8";
export var violetDark = "#9003a8";
export var violetLight = "#e77ef9";
export var white = "#fff";
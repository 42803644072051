import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import * as styles from './LoadingSpinner.module.scss'

const propTypes = {
  disabled: PropTypes.bool,
  size: PropTypes.string,
  variant: PropTypes.string,
}

/**
 * Loading spinner meant for use inside Button. Prop passing
 * for a page-scale component.
 */
const LoadingSpinner = ({ disabled, size, variant }) => (
  <div
    className={classnames(styles.spinner, styles[size], styles[variant], { [styles.disabled]: disabled })}
  />
)

LoadingSpinner.propTypes = propTypes

export default LoadingSpinner

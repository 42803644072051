import React from 'react'
import PropTypes from 'prop-types'

import Icon from 'src/Icon'
import Checkbox from 'src/Checkbox'
import * as styles from './ToggleableSelectionOptions.module.scss'

const propTypes = {
  /** Disables the "select all options" control. */
  disableSelectAll: PropTypes.bool,
  /** Visualizes options as loading, disables controls. */
  loading: PropTypes.bool,
  /** Callback triggered when user clicks on "select all" checkbox. Passing this prop makes the checkbox appear. */
  onToggleAll: PropTypes.func,
  /** List of selectable options. */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      /** ID of option value. */
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      /** Human-readable label of option. */
      text: PropTypes.string,
      /** When false, disabled interacting with this option. */
      isAvailable: PropTypes.bool,
      /** Determines if this option is checked. */
      isSelected: PropTypes.bool,
      /** Controls if the user can delete this option. */
      canDelete: PropTypes.bool,
      /** Optional tooltip displayed on hover over option. */
      info: PropTypes.string,
      /** Controls if tooltip on hover should overflow. */
      tooltip: PropTypes.oneOf(['overflow', 'normal']),
      /** Callback triggered when option is clicked. Option object is first argument. */
      onClick: PropTypes.func,
      /** Callback triggered when option is deleted. Option object is first argument. */
      onDelete: PropTypes.func,
    })
  ).isRequired,
  /** Header text that appears above options. */
  title: PropTypes.string,
}

function ToggleableSelectionOptions({
  disableSelectAll,
  loading = false,
  options,
  onToggleAll,
  title,
}) {
  const selectedOptions = options.filter(o => o.isSelected)
  const noOptionsSelected = selectedOptions.length === 0

  return (
    <React.Fragment>
      {Boolean(title) && (
        <div className={styles.header}>
          {title}
        </div>
      )}
      <ul className={styles.options}>
        {typeof onToggleAll === 'function' && options.length >= 2 && (
          <li className={styles.optionItem}>
            <Checkbox
              disabled={loading || disableSelectAll}
              labelType="linkSmall"
              labelText={noOptionsSelected ? 'Select All' : 'Deselect All'}
              checked={!noOptionsSelected}
              indeterminate={selectedOptions.length > 0 && selectedOptions.length < options.length}
              onChange={() => onToggleAll(noOptionsSelected)}
            />
          </li>
        )}
        {options.map((option, index) => {
          // if there's a delete option and info, label takes up less space
          // if only delete, label takes up slightly more
          // otherwise, just use default label behavior
          const canDelete = Boolean(option.canDelete) && option.onDelete

          // The unique ID has to be *globally* unique in case
          // two different option lists share the same option
          const uniqueId = `${title}-${option.id}-${index}`

          return (
            <li className={styles.optionItem} key={uniqueId}>
              <Checkbox
                disabled={loading || !option.isAvailable}
                labelText={(
                  <React.Fragment>
                    {['net', 'calculated'].includes(option.type) && (
                      <span className={styles.optionTypeIcon}>
                        {option.type === 'net' && (
                          <Icon icon="combine" size="small" color="charcoal" />
                        )}
                        {option.type === 'calculated' && (
                          <Icon icon="calculator" size="small" color="charcoal" />
                        )}
                      </span>
                    )}
                    {option.text}
                  </React.Fragment>
                )}
                value={option.value}
                checked={option.isSelected}
                indeterminate={option.isIndeterminate}
                tooltip={option.info}
                onChange={event => {
                  if (loading || !option.isAvailable) return
                  option.onClick(option, event)
                }}
              />
              <div className={styles.deleteBtnContainer}>
                {canDelete && (
                  <button
                    type="button"
                    title="Delete custom response net"
                    className={styles.deleteBtn}
                    disabled={loading}
                    onClick={event => {
                      if (loading) return
                      event.preventDefault()
                      event.stopPropagation()
                      option?.onDelete(option)
                    }}
                  >
                    <Icon icon="trash" color="inherit" size="small" />
                  </button>
                )}
              </div>
            </li>
          )
        })}
      </ul>
    </React.Fragment>
  )
}

ToggleableSelectionOptions.propTypes = propTypes

export default ToggleableSelectionOptions

import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import { uniqueId } from 'lodash'

import safeComposedPath from 'utils/safeComposedPath'
import { onEscapeKey } from 'utils/keyEvents'
import Icon from '../Icon'
import DropdownMenuItem from './DropdownMenuItem'
import * as styles from './DropdownButton.module.scss'

const requiresIconOrText = propType => (props, propName, componentName, ...rest) => {
  if (!props.icon && !props.text) {
    return new Error(`${componentName}: icon and/or text props are required`)
  }
  return propType(props, propName, componentName, ...rest)
}

const mutuallyRequiredFunctions = (props, propName, componentName, ...rest) => {
  if (Boolean(props.onOpen) !== Boolean(props.onClose)) {
    return new Error(`${componentName}: both onClose and onOpen are required for controlled state`)
  }

  return PropTypes.func(props, propName, componentName, ...rest)
}

const propTypes = {
  /** DEPRECATED: applies css align-self: flex-start */
  alignStart: PropTypes.bool,
  /** Interior dropdown menu content */
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  /** Blocks user from interacting with the dropdown. */
  disabled: PropTypes.bool,
  /** Hides the spinning dropdown arrow, should only be used for triple-dot menus. */
  hideArrow: PropTypes.bool,
  /** Places an icon inside the dropdown trigger, should be a React node. This and/or text a prop value are required. */
  icon: requiresIconOrText(PropTypes.string),
  /** External control of open state. If open/close props are not provided, state is maintained internally. */
  isOpen: PropTypes.bool,
  /** Positions the dropdown to the left, not right. */
  leftAlignDropdown: PropTypes.bool,
  /** Callback that runs when the user triggers closing the dropdown. Requires onOpen to be set. */
  onClose: mutuallyRequiredFunctions,
  /** Callback that runs when the user triggers opening the dropdown. Requires onClose to be set. */
  onOpen: mutuallyRequiredFunctions,
  /** Sets the size of the dropdown trigger. */
  size: PropTypes.oneOf(['large', 'small']),
  /** Dropdown trigger button text. This and/or an icon prop value are required. */
  text: requiresIconOrText(PropTypes.string),
  /** Sets the visual variant of dropdown trigger. */
  type: PropTypes.oneOf(['primary', 'secondary', 'secondaryDark', 'textual', 'textualDark']),
}

const cx = classnames.bind(styles)

/**
 * Though icon/text is all optional, you should have one at least
 * to make it not a blank button.
 */
function DropdownButton({
  children,
  disabled = false,
  hideArrow = false,
  icon,
  isOpen,
  onOpen,
  onClose,
  size = 'large',
  text,
  type = 'textual',
  leftAlignDropdown = false,
  alignStart = false,
}) {
  const [__open, __setOpen] = useState(false) // optional internal state
  const dropdownBodyRef = useRef()
  const dropdownIdRef = useRef(uniqueId('laika-dropdown-'))

  const expanded = isOpen || __open

  useEffect(() => {
    const closeHandler = event => {
      const composedPath = safeComposedPath(event)
      if (
        (event.type === 'click'
          && dropdownBodyRef.current
          // IE: if composedPath is undefined there's a race condition, assuming clicked inside
          && composedPath
          && !composedPath.includes(dropdownBodyRef.current))
        || (event.type === 'keydown' && onEscapeKey(event))
      ) {
        if (onClose !== undefined) {
          onClose(event)
        } else {
          __setOpen(false)
        }
        document.removeEventListener('click', closeHandler)
        document.removeEventListener('keydown', closeHandler)
      }
    }

    if (dropdownBodyRef.current && expanded) {
      document.addEventListener('click', closeHandler)
      document.addEventListener('keydown', closeHandler)
    }
    return () => {
      document.removeEventListener('click', closeHandler)
      document.removeEventListener('keydown', closeHandler)
    }
  }, [onClose, dropdownBodyRef, expanded])

  const containerClass = {}
  containerClass[styles.leftDropdown] = leftAlignDropdown
  containerClass[styles.selfAlignStart] = alignStart

  return (
    <div className={cx(styles.dropdownContainer, containerClass)}>
      <button
        type="button"
        className={cx('dropdownButton', type, size, { expanded, disabled })}
        tabIndex="0"
        disabled={disabled}
        onClick={event => {
          if (!disabled) {
            if (typeof onOpen === 'function') {
              if (isOpen !== undefined && isOpen) {
                onClose(event)
              } else {
                onOpen(event)
              }
            } else {
              __setOpen(!__open)
            }
          }
        }}
        aria-haspopup="true"
        aria-expanded={expanded}
        aria-controls={dropdownIdRef.current}
      >
        {icon && (
          <span className={styles.icon}>
            <Icon icon={icon} size={size === 'large' ? 'medium' : 'small'} />
          </span>
        )}
        {text && (
          <span className={styles.text}>
            {text}
          </span>
        )}
        {!hideArrow && (
          <span className={styles.arrow}>
            <Icon icon="arrowDown" size={size === 'large' ? 'medium' : 'small'} />
          </span>
        )}
      </button>
      {expanded && (
        <div
          ref={dropdownBodyRef}
          className={styles.dropdownMenu}
          aria-labelledby={dropdownIdRef.current}
        >
          {children}
        </div>
      )}
    </div>
  )
}

DropdownButton.propTypes = propTypes
DropdownButton.MenuItem = DropdownMenuItem

export default DropdownButton

import React, { useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { uniqueId } from 'lodash'

import Tooltip from 'src/Tooltip'
import * as styles from './RadioButton.module.scss'

const propTypes = {
  /** Marks this option as selected. */
  checked: PropTypes.bool.isRequired,
  /** Makes this control unable to be changed by the user. */
  disabled: PropTypes.bool,
  /** Label text displayed to the right of the radio button. */
  labelText: PropTypes.string.isRequired,
  /** Field associated with this option. */
  name: PropTypes.string.isRequired,
  /** Event handler called when user attempts to change the radio field value. */
  onChange: PropTypes.func.isRequired,
  /** User is required to give provide a value. Consider using a default value instead. */
  required: PropTypes.bool,
  /** Optional text to show in a tooltip on hover. */
  tooltip: PropTypes.string,
  /** Value sent for name field as part of a form submission. */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
}

function RadioButton({
  checked,
  disabled = false,
  labelText,
  name,
  required,
  onChange,
  tooltip,
  value,
}) {
  const idRef = useRef(uniqueId('laika-radio-'))
  const changeHandler = useCallback(event => {
    onChange(event, value)
  }, [onChange, value])

  return (
    <div className={styles.layout}>
      <input
        id={idRef.current}
        type="radio"
        className={styles.radio}
        disabled={disabled}
        name={name}
        value={value}
        onChange={changeHandler}
        checked={checked}
        required={required}
      />
      <label
        className={classnames(styles.radioLabel, {
          [styles.checked]: checked,
          [styles.disabled]: disabled,
        })}
        htmlFor={idRef.current}
      >
        <Tooltip content={tooltip ?? ''}>
          {({
            hostRef,
            openTooltip,
            closeTooltip,
            attributes,
          }) => (
            <span
              ref={hostRef}
              onMouseEnter={tooltip ? openTooltip : undefined}
              onMouseLeave={closeTooltip}
              {...attributes}
            >
              {labelText}
            </span>
          )}
        </Tooltip>
      </label>
    </div>
  )
}

RadioButton.propTypes = propTypes

export default RadioButton

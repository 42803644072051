/** @file Utility functions for identifying common key presses.
 *
 * For some reason browsers haven't standardized how to identify which key was
 * pressed. It's different across browsers and operating systems, there's three
 * different properties you have to check, and they can have different values
 * depending on the spec.
 *
 * Thanks, browser developers.
 */

const ESCAPE_KEYCODE = 27
const SPACE_KEYCODE = 32
const ENTER_KEYCODE = 13
const TAB_KEYCODE = 9

/**
 * Returns true if user pressed the "Enter" key.
 *
 * @param {KeyboardEvent} event - Keyboard event
 * @returns {boolean} True if user pressed "Enter"
 */
export const onEnterKey = event => event.key === 'Enter'
  || event.code === 'Enter'
  || event.keyCode === ENTER_KEYCODE

/**
 * Returns true if user pressed the "Space" key.
 *
 * @param {KeyboardEvent} event - Keyboard event
 * @returns {boolean} True if user pressed "Space"
 */
export const onSpaceKey = event => event.key === ' '
  || event.code === 'Space'
  || event.keyCode === SPACE_KEYCODE

/**
 * Returns true if user pressed the "Escape" key.
 *
 * @param {KeyboardEvent} event - Keyboard event
 * @returns {boolean} True if user pressed "Escape"
 */
export const onEscapeKey = event => event.key === 'Escape'
  || event.code === 'Escape'
  || event.keyCode === ESCAPE_KEYCODE

/**
 * Returns true if user pressed the "Tab" key.
 *
 * @param {KeyboardEvent} event - Keyboard event
 * @returns {boolean} True if user pressed "Tab"
 */
export const onTabKey = event => event.key === 'Tab'
  || event.code === 'Tab'
  || event.keyCode === TAB_KEYCODE

(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@progress/kendo-data-query"), require("@progress/kendo-react-grid"), require("classnames"), require("classnames/bind"), require("lodash"), require("prop-types"), require("react"), require("react-dom"), require("react-transition-group"));
	else if(typeof define === 'function' && define.amd)
		define(["@progress/kendo-data-query", "@progress/kendo-react-grid", "classnames", "classnames/bind", "lodash", "prop-types", "react", "react-dom", "react-transition-group"], factory);
	else if(typeof exports === 'object')
		exports["laika"] = factory(require("@progress/kendo-data-query"), require("@progress/kendo-react-grid"), require("classnames"), require("classnames/bind"), require("lodash"), require("prop-types"), require("react"), require("react-dom"), require("react-transition-group"));
	else
		root["laika"] = factory(root["@progress/kendo-data-query"], root["@progress/kendo-react-grid"], root["classnames"], root["classnames/bind"], root["lodash"], root["prop-types"], root["react"], root["react-dom"], root["react-transition-group"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__762__, __WEBPACK_EXTERNAL_MODULE__506__, __WEBPACK_EXTERNAL_MODULE__310__, __WEBPACK_EXTERNAL_MODULE__759__, __WEBPACK_EXTERNAL_MODULE__467__, __WEBPACK_EXTERNAL_MODULE__99__, __WEBPACK_EXTERNAL_MODULE__156__, __WEBPACK_EXTERNAL_MODULE__111__, __WEBPACK_EXTERNAL_MODULE__2__) => {
return 
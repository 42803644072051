import { clamp } from 'lodash'

export const calculateStep = (minimum, maximum) => {
  const range = maximum - minimum
  const [num, dec] = range.toPrecision(1).toString().split('.')
  // if the range is >= 10
  if (num.length >= 2) return 1
  // if range is between 6 - 9
  if (num > 5) return 0.1
  // if range is between 1 - 5
  if (!dec) return 0.05
  // make sure there are at least 10 steps between minimum and maximum
  return 1 / (10 ** (dec.length + 1))
}

const getDecimalPrecision = num => {
  // This handles the case when num is very small (0.00000001), JS will turn
  // this into 1e-8.  When num is bigger than 1 or less than -1 it won't get
  // converted to this notation so it's fine.
  if (Math.abs(num) < 1) {
    const parts = num.toExponential().split('e-')
    const matissaDecimalPart = parts[0].split('.')[1]
    return (matissaDecimalPart ? matissaDecimalPart.length : 0) + parseInt(parts[1], 10)
  }

  const decimalPart = num.toString().split('.')[1]
  return decimalPart ? decimalPart.length : 0
}

export const roundValueToStep = (value, step, minimum) => {
  const nearest = Math.round((value - minimum) / step) * step + minimum
  return Number(nearest.toFixed(getDecimalPrecision(step)))
}

export const getSliderStyles = (value, minimum, maximum, type) => {
  const isSingle = !Array.isArray(value) && ['lt', 'lte', 'gt', 'gte'].includes(type)

  if (isSingle) {
    const singleInverted = ['gt', 'gte'].includes(type)

    if (singleInverted) {
      const percent = `${clamp(((value - minimum) * 100) / (maximum - minimum), 0, 100)}%`

      return {
        leftRailStyles: { right: 0, left: percent },
        leftHandleStyles: { left: percent, transform: 'translate(-50%, -50%)' },
        rightRailStyles: { display: 'none' },
        rightHandleStyles: { display: 'none' },
      }
    }
    const percent = `${clamp(100 - ((value - minimum) * 100) / (maximum - minimum), 0, 100)}%`

    return {
      leftRailStyles: { right: percent, left: 0 },
      leftHandleStyles: { right: percent, transform: 'translate(50%, -50%)' },
      rightRailStyles: { display: 'none' },
      rightHandleStyles: { display: 'none' },
    }
  }

  if (type === 'between') {
    return {
      leftRailStyles: {
        left: `${clamp(((value[0] - minimum) * 100) / (maximum - minimum), 0, 100)}%`,
        right: `${clamp(100 - ((value[1] - minimum) * 100) / (maximum - minimum), 0, 100)}%`,
      },
      leftHandleStyles: {
        right: `${clamp(100 - ((value[0] - minimum) * 100) / (maximum - minimum), 0, 100)}%`,
        transform: 'translate(50%, -50%)',
      },
      rightHandleStyles: {
        left: `${clamp(((value[1] - minimum) * 100) / (maximum - minimum), 0, 100)}%`,
        transform: 'translate(-50%, -50%)',
      },
      rightRailStyles: { display: 'none' },
    }
  }

  if (type === 'notbetween') {
    const leftPercent = `${clamp(
      100 - ((value[0] - minimum) * 100) / (maximum - minimum),
      0,
      100
    )}%`
    const rightPercent = `${clamp(((value[1] - minimum) * 100) / (maximum - minimum), 0, 100)}%`

    return {
      leftRailStyles: { right: leftPercent, left: 0 },
      leftHandleStyles: { right: leftPercent, transform: 'translate(50%, -50%)' },
      rightHandleStyles: { left: rightPercent, transform: 'translate(-50%, -50%)' },
      rightRailStyles: { right: 0, left: rightPercent },
    }
  }

  throw new Error('SliderInput: Styles can not be constructed from these settings')
}

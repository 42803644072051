import React, {
  useCallback,
  useRef,
  useState,
} from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import { usePopper } from 'react-popper'
import { CSSTransition } from 'react-transition-group'
import classnames from 'classnames'
import { uniqueId } from 'lodash'

import * as styles from './Tooltip.module.scss'

const propTypes = {
  /**
   * Host of the tooltip, i.e. where it is placed.
   * Can be a node or a function that returns a node. Function passes a managed ref as
   * an argument that can be used to attach the tooltip deeper in your component. It also
   * passes additional element attributes to apply for A11Y.
   */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  /** Content that appears in the tooltip */
  content: PropTypes.node.isRequired,
  /** Text inside the button in dismissible tooltips. */
  dismissButtonText: PropTypes.string,
  /** Decides whether dismissible tooltip is shown. */
  isOpen: PropTypes.bool,
  /** Callback when text is dismissed */
  onDismiss: PropTypes.func,
  /**
   * Preferred placement of the tooltip relative to the host.
   * See https://popper.js.org/docs/v2/constructors/#options
   */
  placement: PropTypes.oneOf([
    'auto',
    'auto-start',
    'auto-end',
    'top',
    'top-start',
    'top-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'left',
    'left-start',
    'left-end',
    'right',
    'right-start',
    'right-end',
  ]),
  /**
   * ID specifying the element where the modal will populate.
   * This differs between Embed and Customer. Can be manually
   * set via this prop or set via the environment variable
   * REACT_APP_LAIKA_TOOLTIP_ROOT.
   */
  tooltipRoot: PropTypes.string,
  /**
   * Visual variant. 'standard' is the standard dark style,
   * 'dismissible' stays open until user closes it.
   */
  variant: PropTypes.oneOf(['standard', 'dismissible']),
}

const BASE_TIMING = Number(styles.baseTimingInt)
const TOOLTIP_ROOT = process.env.REACT_APP_LAIKA_TOOLTIP_ROOT ?? 'tooltip-root'

function Tooltip({
  children,
  content,
  dismissButtonText = 'Got it',
  isOpen,
  onDismiss,
  placement = 'auto',
  tooltipRoot = TOOLTIP_ROOT,
  variant = 'standard',
}) {
  const tooltipId = useRef(uniqueId('laika-tooltip-'))
  const tooltipRootElRef = useRef(document.getElementById(tooltipRoot))
  const [hostElement, setHostElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles: popperStyles, attributes } = usePopper(hostElement, popperElement, {
    placement,
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      { name: 'offset', options: { offset: [0, 8] } },
    ],
  })
  const [internalIsOpen, setInternalIsOpen] = useState(variant === 'dismissible')
  const [internalDismissed, setIntervalDismissed] = useState(false)
  const closeTooltip = useCallback(() => setInternalIsOpen(false), [setInternalIsOpen])
  const openTooltip = useCallback(() => setInternalIsOpen(true), [setInternalIsOpen])
  const dismissTooltip = useCallback(event => {
    event.stopPropagation()
    setIntervalDismissed(true)
    closeTooltip()
    if (onDismiss) onDismiss()
  }, [closeTooltip, onDismiss])

  const __isOpen = isOpen ?? (
    (variant === 'dismissible' && !internalDismissed)
    || (variant === 'standard' && internalIsOpen)
  )
  const [popperPlacement] = (attributes?.popper?.['data-popper-placement'] || '').split('-')

  return (
    <React.Fragment>
      {typeof children === 'function' ? children({
        hostRef: setHostElement,
        openTooltip,
        closeTooltip,
        attributes: {
          'aria-describedby': tooltipId.current,
          'aria-haspopup': 'true',
          'aria-expanded': __isOpen,
        },
      }) : (
        <div
          onMouseOver={openTooltip}
          onMouseOut={closeTooltip}
          onFocus={openTooltip}
          onBlur={closeTooltip}
          ref={setHostElement}
          aria-describedby={tooltipId.current}
          aria-haspopup="true"
          aria-expanded={__isOpen}
        >
          {children}
        </div>
      )}
      {createPortal(
        (
          <CSSTransition
            in={__isOpen}
            timeout={BASE_TIMING}
            classNames={{
              enter: styles.tooltipEnter,
              enterActive: styles.tooltipEnterActive,
              enterDone: styles.tooltipEnterDone,
              exit: styles.tooltipExit,
              exitActive: styles.tooltipExitActive,
              exitDone: styles.tooltipExitDone,
            }}
            unmountOnExit
          >
            <div
              id={tooltipId.current}
              ref={setPopperElement}
              style={popperStyles.popper}
              className={classnames(styles.tooltip, styles[variant])}
              role="tooltip"
              {...attributes.popper}
            >
              {content}
              {variant === 'dismissible' && (
                <div className={styles.buttonContainer}>
                  <button
                    type="button"
                    className={styles.dismissButton}
                    onClick={dismissTooltip}
                  >
                    {dismissButtonText}
                  </button>
                </div>
              )}
              <div
                ref={setArrowElement}
                className={classnames(styles.arrow, styles[popperPlacement])}
                style={{
                  ...popperStyles.arrow,
                  transform: `${popperStyles.arrow.transform} rotate(45deg)`,
                }}
                aria-hidden="true"
              />
            </div>
          </CSSTransition>
        ),
        tooltipRootElRef.current
      )}
    </React.Fragment>
  )
}

Tooltip.propTypes = propTypes

export default Tooltip

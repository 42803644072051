import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

import Icon from '../../Icon'
import * as styles from './DropdownMenuItem.module.scss'

const propTypes = {
  /** Adds active class */
  active: PropTypes.bool,
  /** JSX that renders the dropdown menu items */
  children: PropTypes.node.isRequired,
  /** Disables onClick and adds disabled class */
  disabled: PropTypes.bool,
  /** Interior icon on the menu item. */
  icon: PropTypes.string,
  /** Styles the item as the parent of a nested value */
  isParent: PropTypes.bool,
  /** Function that runs when the button is clicked */
  onClick: PropTypes.func,
  /** Function that runs when user clicks button to descend in nested dropdowns. Implicitly creates arrow button. */
  onNextChild: PropTypes.func,
  /** Adds active class */
  small: PropTypes.bool,
}

const cx = classnames.bind(styles)

function DropdownMenuItem({
  active = false,
  children,
  disabled = false,
  icon,
  isParent = false,
  onClick,
  onNextChild,
  small = false,
}) {
  return (
    <div // eslint-disable-line jsx-a11y/click-events-have-key-events -- FIXME
      className={cx('dropdownMenuItem', {
        active, disabled, small, isParent,
      })}
      onClick={disabled ? null : onClick}
    >
      {isParent && (
        <span className={styles.parentArrow}>
          <Icon icon="arrowDown" size="small" color="charcoal" />
        </span>
      )}
      {icon && (
        <span className={styles.icon}>
          <Icon icon={icon} size="small" color="charcoal" />
        </span>
      )}
      {children}
      {!disabled && typeof onNextChild === 'function' && (
        <button className={styles.nestedArrow} onClick={onNextChild}>
          <Icon icon="arrowDown" size="small" color="charcoal" />
        </button>
      )}
    </div>
  )
}

DropdownMenuItem.propTypes = propTypes

export default DropdownMenuItem

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import * as styles from './LoadingSpinner.module.scss'

const propTypes = {
  /** Centers the spinner within the nearest relatively positioned container. */
  centered: PropTypes.bool,
  /** Optional text that appears below the spinner. */
  text: PropTypes.string,
}

/**
 * This is a cleaner copy of the LineScale component from UITheme,
 * which is based on Load Awesome. Not officially part of the design
 * system, will likely be replaced with something else later. This
 * was copied over to continue deprecating UITheme.
 */
const LoadingSpinner = ({ centered, text }) => (
  <div className={classnames(styles.container, { [styles.centered]: centered })}>
    <div className={styles.spinner}>
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
    {text && (
      <p className={styles.text}>
        {text}
      </p>
    )}
  </div>
)

LoadingSpinner.propTypes = propTypes

export default LoadingSpinner

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import * as styles from './Icon.module.scss'
import iconMapping from './assets'

const propTypes = {
  /** Color of the icon. */
  color: PropTypes.oneOf(['charcoal', 'violet', 'khBlue', 'scarlet', 'lime', 'inherit', 'disabled']),
  /** Icon glyph to visualize. */
  icon: PropTypes.oneOf(Object.keys(iconMapping)).isRequired,
  /** Dimensions of the icon. */
  size: PropTypes.oneOf(['small', 'medium', 'large', 'extraLarge']),
}

const Icon = React.forwardRef(({ color = 'charcoal', icon, size = 'medium' }, ref) => (
  <svg
    ref={ref}
    role="img"
    className={classnames(styles.icon, styles[size], styles[color])}
    data-test={icon}
  >
    <use xlinkHref={`#${iconMapping[icon]}`} />
  </svg>
))

Icon.displayName = 'Icon'
Icon.propTypes = propTypes

export default Icon

import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { uniqueId } from 'lodash'

import RadioButton from './RadioButton'

const propTypes = {
  /** Disable all options belonging to this group. See options.disabled for individual controls. */
  disabled: PropTypes.bool,
  /**
   * Field name the radio options are connected to. A unique value will be provided by default.
   * Keep that in mind if submitted as a form, as the name is sent as part of the request.
   */
  name: PropTypes.string,
  /** Callback triggered when user attempts to change a radio value. */
  onChange: PropTypes.func.isRequired,
  /** List of user selection options that belong to this radio group. */
  options: PropTypes.arrayOf(PropTypes.shape({
    /** Marks this option as selected. */
    checked: PropTypes.bool,
    /** Makes this control unable to be changed by the user. */
    disabled: PropTypes.bool,
    /** Label text displayed to the right of the radio button. */
    labelText: PropTypes.string,
    /** User is required to give provide a value. Consider using a default value instead. */
    required: PropTypes.bool,
    /** Optional text to show in a tooltip on hover. */
    tooltip: PropTypes.string,
    /** Value sent for name field as part of a form submission. */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
  })),
}

function RadioButtonList({
  disabled = false,
  name,
  onChange,
  options,
}) {
  const nameRef = useRef(uniqueId('laika-radio-list-'))
  return (
    <React.Fragment>
      {options.map((option, index) => (
        <RadioButton
          key={`${index}-${option.labelText}`}
          disabled={disabled || option.disabled}
          name={name ?? nameRef.current}
          value={option.value}
          checked={option.checked}
          onChange={onChange}
          labelText={option.labelText}
          tooltip={option.tooltip}
          required={option.required}
        />
      ))}
    </React.Fragment>
  )
}

RadioButtonList.propTypes = propTypes

export default RadioButtonList

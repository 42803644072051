// extracted by mini-css-extract-plugin
export var baseTiming = "125ms";
export var baseTimingInt = "125";
export var bodySmall = "Checkbox-module__body-small--2St7c";
export var borderRadius = "3px";
export var charcoal = "#2d2e2e";
export var checkbox = "Checkbox-module__checkbox--E1Wpv";
export var checkboxContainer = "Checkbox-module__checkbox-container--7CV2e";
export var checkboxLabel = "Checkbox-module__checkbox-label--1RBcA";
export var cloudGrey = "#c8cdcf";
export var disabled = "Checkbox-module__disabled--2D1UI";
export var doveGrey = "#f1f3f5";
export var khBlue = "#04b1e0";
export var khBlueDark = "#0376ae";
export var khBlueLight = "#7de1f4";
export var lemon = "#fae436";
export var lemonDark = "#e3cd02";
export var lemonLight = "#fff284";
export var lime = "#05c150";
export var limeDark = "#068230";
export var limeLight = "#65ec83";
export var linkSmall = "Checkbox-module__link-small--i2sYC";
export var navy = "#052976";
export var navyDark = "#04173f";
export var navyLight = "#82b1f6";
export var scarlet = "#e9062b";
export var scarletDark = "#9d050e";
export var scarletLight = "#f5726d";
export var stormGrey = "#969b9c";
export var violet = "#d401f8";
export var violetDark = "#9003a8";
export var violetLight = "#e77ef9";
export var white = "#fff";
export var wrap = "Checkbox-module__wrap---5Mp2";
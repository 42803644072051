import React, {
  useRef,
  useCallback,
  useEffect,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { uniqueId } from 'lodash'

import Tooltip from 'src/Tooltip'
import { onEnterKey, onSpaceKey } from 'src/utils/keyEvents'
import * as styles from './Checkbox.module.scss'

const propTypes = {
  /** Whether the checkbox is checked. */
  checked: PropTypes.bool.isRequired,
  /** Makes this control unable to be changed by the user. */
  disabled: PropTypes.bool,
  /** Whether the checkbox is in the 'indeterminate' (minus sign) state */
  indeterminate: PropTypes.bool,
  /** Input label content displayed next to the checkbox. */
  labelText: PropTypes.node,
  /** Indicates if text should wrap, or overflow with a tooltip. */
  labelTextWrap: PropTypes.bool,
  /** Select which typeset is used on the label. */
  labelType: PropTypes.oneOf(['bodySmall', 'linkSmall']),
  /** If submitted as a form, the key for the value server receives. */
  name: PropTypes.string,
  /** Function triggered when the input checked state changes. */
  onChange: PropTypes.func.isRequired,
  /** Function triggered when the user performs a shift click. */
  onShiftClick: PropTypes.func,
  /** What if anything should be displayed in a tooltip. */
  tooltip: PropTypes.node,
  /** Influence the tooltip placement around the label. */
  tooltipPlacement: PropTypes.string,
  /** Unseen on client side, but if submitted as a form the server sees this as the value. */
  value: PropTypes.string,
}

function Checkbox({
  checked,
  disabled = false,
  indeterminate,
  labelText = '',
  labelTextWrap = false,
  labelType = 'bodySmall',
  name,
  onChange,
  onShiftClick,
  tooltip,
  tooltipPlacement = 'auto',
  value,
}) {
  const inputRef = useRef()
  const idRef = useRef(uniqueId('laika-checkbox-'))
  const [labelContainerElement, setLabelContainerElement] = useState()
  const [hasOverflow, setHasOverflow] = useState(false)
  const [tooltipOpen, setIsTooltipOpen] = useState(false)
  const openTooltip = useCallback(() => setIsTooltipOpen(true), [setIsTooltipOpen])
  const closeTooltip = useCallback(() => setIsTooltipOpen(false), [setIsTooltipOpen])
  const shiftEventHandler = useCallback(event => {
    if (
      !disabled
      && event.shiftKey
      && (event.type === 'click' || onEnterKey(event) || onSpaceKey(event))
    ) {
      event.preventDefault()
      onShiftClick(event)
    }
  }, [disabled, onShiftClick])

  useEffect(() => {
    if (labelContainerElement) {
      setHasOverflow(
        (!labelTextWrap && labelContainerElement.scrollWidth > labelContainerElement.clientWidth)
      )
    }
  }, [labelContainerElement, labelTextWrap])

  useEffect(() => {
    // Can not be set via attribute, has to be IDL because why not W3C?
    inputRef.current.indeterminate = indeterminate
  }, [indeterminate, inputRef])

  return (
    <div className={styles.checkboxContainer}>
      <input
        ref={inputRef}
        className={styles.checkbox}
        id={idRef.current}
        type="checkbox"
        name={name}
        disabled={disabled}
        value={value}
        onChange={onChange}
        onClick={shiftEventHandler}
        checked={checked}
      />
      <Tooltip
        placement={tooltipPlacement}
        isOpen={tooltipOpen && ((!tooltip && hasOverflow && !labelTextWrap) || Boolean(tooltip))}
        content={tooltip ?? labelText}
      >
        {({ hostRef, attributes }) => (
          <label // eslint-disable-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events -- Input takes focus
            ref={ref => {
              setLabelContainerElement(ref)
              hostRef(ref)
            }}
            htmlFor={idRef.current}
            onMouseEnter={openTooltip}
            onMouseLeave={closeTooltip}
            onClick={shiftEventHandler}
            className={classnames(
              styles.checkboxLabel,
              styles[labelType],
              {
                [styles.disabled]: disabled,
                [styles.wrap]: labelTextWrap,
              }
            )}
            {...attributes}
          >
            {labelText}
          </label>
        )}
      </Tooltip>
    </div>
  )
}

Checkbox.propTypes = propTypes

export default Checkbox

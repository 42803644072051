import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Button from '../../Button'
import Icon from '../../Icon'
import useCustomValidity from '../useCustomValidity'
import * as inputStyles from '../FormInput.module.scss'
import * as styles from './SearchInput.module.scss'

const propTypes = {
  /** Controls if user can interact with input */
  disabled: PropTypes.bool,
  /** Marks the input as invalid. The message is not visible, but read by screen readers. */
  errorMessage: PropTypes.string,
  /** Optional HTML ID of the input. */
  id: PropTypes.string,
  /** Set a visual loading state for the search input. */
  isLoading: PropTypes.bool,
  /** Used to identify search input, key of passed value in forms. */
  name: PropTypes.string,
  /** Callback triggered when user changes the value in the input. */
  onChange: PropTypes.func.isRequired,
  /** Text value shown in input before user types. */
  placeholder: PropTypes.string,
  /** Controls the size of the input. Navigation is meant for the top nav bar. */
  size: PropTypes.oneOf(['navigation', 'large', 'small']),
  /** Text value of the search input. */
  value: PropTypes.string.isRequired,
}

const SearchInput = React.forwardRef(
  (
    {
      disabled = false,
      errorMessage,
      id,
      isLoading = false,
      name,
      onChange,
      placeholder = 'Search',
      size = 'large',
      value,
    },
    forwardedRef
  ) => {
    const localRef = useRef()
    const ref = forwardedRef ?? localRef

    useCustomValidity(ref, errorMessage)

    return (
      <div
        className={classnames(
          styles.searchContainer,
          styles[size],
          { [styles.active]: Boolean(value) && value.length }
        )}
      >
        <div className={classnames(styles.icon, { [styles.loading]: isLoading })}>
          <Icon icon={isLoading ? 'sync' : 'search'} size="medium" color="charcoal" />
        </div>
        <input
          id={id}
          ref={ref}
          value={value}
          type="search"
          name={name ?? id}
          placeholder={placeholder}
          className={classnames(inputStyles.formInput, styles.searchInput)}
          disabled={disabled}
          onChange={onChange}
        />
        {size === 'navigation' ? (
          <Button
            variant="primary"
            size="small"
            disabled={disabled}
            onClick={event => onChange({ ...event, target: { ...event.target, value: '' } })}
          >
            Clear
          </Button>
        ) : (
          <button
            className={styles.normalClose}
            disabled={disabled}
            onClick={event => onChange({ ...event, target: { ...event.target, value: '' } })}
          >
            <Icon icon="close" size="small" />
          </button>
        )}
      </div>
    )
  }
)

SearchInput.displayName = 'SearchInput'
SearchInput.propTypes = propTypes

export default SearchInput

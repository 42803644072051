// extracted by mini-css-extract-plugin
export var baseTiming = "125ms";
export var baseTimingInt = "125";
export var borderRadius = "3px";
export var charcoal = "#2d2e2e";
export var cloudGrey = "#c8cdcf";
export var content = "Modal-module__content--1jLUd";
export var dialog = "Modal-module__dialog--BCMug";
export var doveGrey = "#f1f3f5";
export var footer = "Modal-module__footer--1BBdl";
export var header = "Modal-module__header--1NmWN";
export var khBlue = "#04b1e0";
export var khBlueDark = "#0376ae";
export var khBlueLight = "#7de1f4";
export var lemon = "#fae436";
export var lemonDark = "#e3cd02";
export var lemonLight = "#fff284";
export var lime = "#05c150";
export var limeDark = "#068230";
export var limeLight = "#65ec83";
export var modal = "Modal-module__modal--2h_Gm";
export var modalEnter = "Modal-module__modal-enter--FCM-I";
export var modalEnterActive = "Modal-module__modal-enter-active--2mBhX";
export var modalEnterDone = "Modal-module__modal-enter-done--3j1j-";
export var modalExit = "Modal-module__modal-exit--11-L8";
export var modalExitActive = "Modal-module__modal-exit-active--1L2P5";
export var modalExitDone = "Modal-module__modal-exit-done--NU-Af";
export var modalOverlay = "Modal-module__modal-overlay--2s8Qc";
export var modalTiming = "312.5";
export var navy = "#052976";
export var navyDark = "#04173f";
export var navyLight = "#82b1f6";
export var scarlet = "#e9062b";
export var scarletDark = "#9d050e";
export var scarletLight = "#f5726d";
export var slideRight = "Modal-module__slide-right--1_8HQ";
export var standard = "Modal-module__standard--3ADCL";
export var stormGrey = "#969b9c";
export var title = "Modal-module__title--kCUzZ";
export var violet = "#d401f8";
export var violetDark = "#9003a8";
export var violetLight = "#e77ef9";
export var white = "#fff";
import { useEffect, useState } from 'react'

/**
 * Limit the rate of changes to a value to a specified delay.
 * @param {any} value - The value to be rate-limited.
 * @param {number} delay - The interval between value changes in ms.
 */
export default function useDebounce(value, delay = 125) {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}

import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { onEnterKey, onSpaceKey } from 'src/utils/keyEvents'

import * as styles from './ToggleSwitch.module.scss'

const propTypes = {
  /** whether the switch can be toggled or not */
  disabled: PropTypes.bool,
  /** id for htmlFor */
  id: PropTypes.string,
  /** whether the switch is on or off */
  isToggled: PropTypes.bool.isRequired,
  /** Function triggered when switch toggled */
  onToggle: PropTypes.func.isRequired,
}

const ToggleSwitch = ({
  id,
  isToggled,
  onToggle,
  disabled = false,
}) => {
  const handleKeyDown = useCallback(event => {
    if (!disabled && (event.type === 'click' || onEnterKey(event) || onSpaceKey(event))) {
      event.preventDefault()
      onToggle()
    }
  }, [onToggle, disabled])

  return (
    <div className={`${styles.track} ${isToggled ? styles.toggled : ''} ${disabled ? styles.disabled : ''}`}
      onClick={onToggle}
      onKeyDown={handleKeyDown}
      role='switch'
      aria-checked={isToggled}
      aria-disabled={disabled}
      id={id}
      tabIndex='0'
    >
      <div className={styles.thumb}>
        <div className={`${styles.hoverThumb} ${disabled ? styles.disabled : ''}`}></div>
      </div>
    </div>
  )
}

ToggleSwitch.propTypes = propTypes

export default ToggleSwitch

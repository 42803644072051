import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Icon from '../Icon'
import * as styles from './IconButton.module.scss'

const propTypes = {
  /** INTERNAL USE ONLY: Do not pass a value to this prop. */
  __checkedPropRequiredWhenSwitch(props, propName, componentName) {
    if (props[propName]) {
      return new Error(`${componentName} do not pass a value to ${propName}.`)
    }
    if (props.variant === 'switch' && typeof props.checked !== 'boolean') {
      return new Error(
        `${componentName} requires a boolean value for 'checked' prop when used as a switch.`
      )
    }
    if (props.variant === 'button' && typeof props.checked === 'boolean') {
      return new Error(
        `${componentName} does not expect a boolean value for 'checked' prop when used as a button.`
      )
    }
    return undefined
  },
  /** Called when use interacts with the input. Could be a click or change event depending on variant. */
  callback: PropTypes.func.isRequired,
  /** Value of the input when used as a switch. Required when variant="switch". */
  checked: PropTypes.bool,
  /** Prevents the user from interacting with input when true. */
  disabled: PropTypes.bool,
  /** Icon to display inside the input. See `Icon` for full list. */
  icon: PropTypes.string.isRequired,
  /** Controls the size of the input. */
  size: PropTypes.oneOf(['large', 'small']),
  /** Visual style of input. `switch` is stateful, where as `button` is not. 'outlined' options are visual only and will act as buttons */
  variant: PropTypes.oneOf(['button', 'switch', 'outlinedKhBlue', 'outlinedScarlet']),
}

const IconButton = React.forwardRef(({
  callback,
  checked,
  disabled = false,
  icon,
  size = 'large',
  variant = 'button',
}, ref) => (
  <button
    disabled={disabled}
    onClick={callback}
    className={classnames(styles.button, styles[size], styles[variant], { [styles.checked]: checked })}
    role={variant === 'switch' ? 'checkbox' : 'button'}
    aria-checked={variant === 'switch' ? checked : undefined}
    tabIndex={0}
    ref={ref}
  >
    <Icon icon={icon} color="inherit" />
  </button>
))

IconButton.displayName = 'IconButton'
IconButton.propTypes = propTypes

export default IconButton

// extracted by mini-css-extract-plugin
export var baseTiming = "125ms";
export var baseTimingInt = "125";
export var borderRadius = "3px";
export var charcoal = "Icon-module__charcoal--1_x-7";
export var cloudGrey = "#c8cdcf";
export var disabled = "Icon-module__disabled--2Ste8";
export var doveGrey = "#f1f3f5";
export var extraLarge = "Icon-module__extra-large--1kGAk";
export var icon = "Icon-module__icon--2C5hP";
export var inherit = "Icon-module__inherit--2zgd2";
export var khBlue = "Icon-module__kh-blue--3MmAC";
export var khBlueDark = "#0376ae";
export var khBlueLight = "#7de1f4";
export var large = "Icon-module__large--sL-O6";
export var lemon = "#fae436";
export var lemonDark = "#e3cd02";
export var lemonLight = "#fff284";
export var lime = "Icon-module__lime--18cFk";
export var limeDark = "#068230";
export var limeLight = "#65ec83";
export var medium = "Icon-module__medium--14_kF";
export var navy = "#052976";
export var navyDark = "#04173f";
export var navyLight = "#82b1f6";
export var scarlet = "Icon-module__scarlet--2jaJ5";
export var scarletDark = "#9d050e";
export var scarletLight = "#f5726d";
export var small = "Icon-module__small--3PsG6";
export var stormGrey = "#969b9c";
export var violet = "Icon-module__violet--3OSM6";
export var violetDark = "#9003a8";
export var violetLight = "#e77ef9";
export var white = "#fff";
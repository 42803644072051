// extracted by mini-css-extract-plugin
export var arrow = "DropdownButton-module__arrow--179KW";
export var baseTiming = "125ms";
export var baseTimingInt = "125";
export var borderRadius = "3px";
export var charcoal = "#2d2e2e";
export var cloudGrey = "#c8cdcf";
export var disabled = "DropdownButton-module__disabled--c8yMT";
export var doveGrey = "#f1f3f5";
export var dropdownButton = "DropdownButton-module__dropdown-button--2w-8f";
export var dropdownContainer = "DropdownButton-module__dropdown-container--3xRrX";
export var dropdownMenu = "DropdownButton-module__dropdown-menu--3XHJu";
export var expanded = "DropdownButton-module__expanded--1JFkN";
export var icon = "DropdownButton-module__icon--3RNqN";
export var khBlue = "#04b1e0";
export var khBlueDark = "#0376ae";
export var khBlueLight = "#7de1f4";
export var large = "DropdownButton-module__large--2CR64";
export var leftDropdown = "DropdownButton-module__left-dropdown--3EDTf";
export var lemon = "#fae436";
export var lemonDark = "#e3cd02";
export var lemonLight = "#fff284";
export var lime = "#05c150";
export var limeDark = "#068230";
export var limeLight = "#65ec83";
export var navy = "#052976";
export var navyDark = "#04173f";
export var navyLight = "#82b1f6";
export var primary = "DropdownButton-module__primary--1YL0r";
export var scarlet = "#e9062b";
export var scarletDark = "#9d050e";
export var scarletLight = "#f5726d";
export var secondary = "DropdownButton-module__secondary--2t6WC";
export var secondaryDark = "DropdownButton-module__secondary-dark--1wL5G";
export var selfAlignStart = "DropdownButton-module__self-align-start--tVc4W";
export var small = "DropdownButton-module__small--1Glcd";
export var stormGrey = "#969b9c";
export var text = "DropdownButton-module__text--1H8UQ";
export var textual = "DropdownButton-module__textual--3ZM1X";
export var textualDark = "DropdownButton-module__textual-dark--2zuR1";
export var violet = "#d401f8";
export var violetDark = "#9003a8";
export var violetLight = "#e77ef9";
export var white = "#fff";
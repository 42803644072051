import AddSprite from './add.sprite.svg'
import AddContentSprite from './add_content.sprite.svg'
import AdjustSprite from './adjust.sprite.svg'
import AnswerSprite from './answer.sprite.svg'
import ArrowDownSprite from './arrow_down.sprite.svg'
import ArrowUpSprite from './arrow_up.sprite.svg'
import BreakoutsSprite from './breakouts.sprite.svg'
import CalculatorSprite from './calculator.sprite.svg'
import ChartBarSprite from './chart_bar.sprite.svg'
import ChartColumnSprite from './chart_column.sprite.svg'
import ChartLineSprite from './chart_line.sprite.svg'
import ChartStackedBarSprite from './chart_stacked_bar.sprite.svg'
import ChartStackedColumnSprite from './chart_stacked_column.sprite.svg'
import CheckSprite from './check.sprite.svg'
import ClockSprite from './clock.sprite.svg'
import CloseSprite from './close.sprite.svg'
import CombineSprite from './combine.sprite.svg'
import CompassSprite from './compass.sprite.svg'
import CopyToClipboardSprite from './copy_to_clipboard.sprite.svg'
import CustomizeSprite from './customize.sprite.svg'
import DownloadSprite from './download.sprite.svg'
import EditSprite from './edit.sprite.svg'
import ErrorSprite from './error.sprite.svg'
import FavoriteSprite from './favorite.sprite.svg'
import FavoritedSprite from './favorited.sprite.svg'
import FileSprite from './file.sprite.svg'
import FilterSprite from './filter.sprite.svg'
import FlipAxisSprite from './flip_axis.sprite.svg'
import InfoSprite from './info.sprite.svg'
import IntegrationSprite from './integrations.sprite.svg'
import InvisibleSprite from './invisible.sprite.svg'
import LabelsSprite from './labels.sprite.svg'
import LinkSprite from './link.sprite.svg'
import LoadSprite from './load.sprite.svg'
import LockedSprite from './locked.sprite.svg'
import LowBaseSprite from './low_base.sprite.svg'
import MatrixSprite from './matrix.sprite.svg'
import MeansSprite from './means.sprite.svg'
import MoreSprite from './more.sprite.svg'
import MoveSprite from './move.sprite.svg'
import NewWindowSprite from './new_window.sprite.svg'
import NumericalSprite from './numerical.sprite.svg'
import OpenEndedSprite from './open_ended.sprite.svg'
import PercentSprite from './percent.sprite.svg'
import PhonebookSprite from './phonebook.sprite.svg'
import PresentationSprite from './presentation.sprite.svg'
import ProfileSprite from './profile.sprite.svg'
import PublicSprite from './public.sprite.svg'
import ReportSprite from './report.sprite.svg'
import SearchSprite from './search.sprite.svg'
import SettingsSprite from './settings.sprite.svg'
import ShareSprite from './share.sprite.svg'
import SortAscendingSprite from './sort_ascending.sprite.svg'
import SortDefaultSprite from './sort_default.sprite.svg'
import SortDescendingSprite from './sort_descending.sprite.svg'
import SpreadsheetSprite from './spreadsheet.sprite.svg'
import StatsSprite from './stats.sprite.svg'
import StudySprite from './study.sprite.svg'
import SummarySprite from './summary.sprite.svg'
import SwapSprite from './swap.sprite.svg'
import SyncSprite from './sync.sprite.svg'
import TagSprite from './tag.sprite.svg'
import TrashSprite from './trash.sprite.svg'
import UndoSprite from './undo.sprite.svg'
import UnlockedSprite from './unlocked.sprite.svg'
import VideoSprite from './video.sprite.svg'
import ViewSprite from './view.sprite.svg'

const spriteMapping = {
  add: AddSprite.id,
  addContent: AddContentSprite.id,
  adjust: AdjustSprite.id,
  answer: AnswerSprite.id,
  arrowDown: ArrowDownSprite.id,
  arrowUp: ArrowUpSprite.id,
  breakouts: BreakoutsSprite.id,
  calculator: CalculatorSprite.id,
  chartBar: ChartBarSprite.id,
  chartColumn: ChartColumnSprite.id,
  chartLine: ChartLineSprite.id,
  chartStackedBar: ChartStackedBarSprite.id,
  chartStackedColumn: ChartStackedColumnSprite.id,
  check: CheckSprite.id,
  clock: ClockSprite.id,
  close: CloseSprite.id,
  combine: CombineSprite.id,
  compass: CompassSprite.id,
  copyToClipboard: CopyToClipboardSprite.id,
  customize: CustomizeSprite.id,
  download: DownloadSprite.id,
  edit: EditSprite.id,
  error: ErrorSprite.id,
  favorite: FavoriteSprite.id,
  favorited: FavoritedSprite.id,
  file: FileSprite.id,
  filter: FilterSprite.id,
  flipAxis: FlipAxisSprite.id,
  info: InfoSprite.id,
  integrations: IntegrationSprite.id,
  invisible: InvisibleSprite.id,
  labels: LabelsSprite.id,
  link: LinkSprite.id,
  load: LoadSprite.id,
  locked: LockedSprite.id,
  lowBase: LowBaseSprite.id,
  matrix: MatrixSprite.id,
  means: MeansSprite.id,
  more: MoreSprite.id,
  move: MoveSprite.id,
  newWindow: NewWindowSprite.id,
  numerical: NumericalSprite.id,
  openEnded: OpenEndedSprite.id,
  percent: PercentSprite.id,
  phonebook: PhonebookSprite.id,
  presentation: PresentationSprite.id,
  profile: ProfileSprite.id,
  public: PublicSprite.id,
  report: ReportSprite.id,
  search: SearchSprite.id,
  settings: SettingsSprite.id,
  share: ShareSprite.id,
  sortAscending: SortAscendingSprite.id,
  sortDefault: SortDefaultSprite.id,
  sortDescending: SortDescendingSprite.id,
  spreadsheet: SpreadsheetSprite.id,
  stats: StatsSprite.id,
  study: StudySprite.id,
  summary: SummarySprite.id,
  swap: SwapSprite.id,
  sync: SyncSprite.id,
  tag: TagSprite.id,
  trash: TrashSprite.id,
  undo: UndoSprite.id,
  unlocked: UnlockedSprite.id,
  video: VideoSprite.id,
  view: ViewSprite.id,
}

export default spriteMapping

import React, { useRef, useEffect } from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import cs from 'classnames'
import { CSSTransition } from 'react-transition-group'

import IconButton from 'src/IconButton'
import Button from 'src/Button'
import { onEscapeKey } from 'src/utils/keyEvents'
import * as styles from './Modal.module.scss'

const propTypes = {
  __secondaryActionPropValidation(props, propName, componentName) {
    if (props.onSecondaryAction && !props.secondaryActionText) {
      return new Error(
        `${componentName}: onSecondaryAction prop requires button text prop secondaryActionText.`
      )
    }
    return undefined
  },
  __submitRequiredWhenStandardModal(props, propName, componentName) {
    if (props.variant !== 'dialog' && !props.onSubmit) {
      return new Error(
        `${componentName}: onSubmit prop required when using standard/slideRight variants.`
      )
    }
    return undefined
  },
  /** Content that appears inside the modal. */
  children: PropTypes.node.isRequired,
  /** Prevent modal from closing. Useful for blocking loading states. */
  closeDisabled: PropTypes.bool,
  /** Text that appears in the textual button in the footer, closes the modal. */
  closeText: PropTypes.string,
  /** Controls whether the modal is visible to the user. */
  isOpen: PropTypes.bool.isRequired,
  /**
   * ID specifying the element where the modal will populate.
   * This differs between Embed and Customer. Can be overriden by
   * this prop or set globally via the environment variable
   * REACT_APP_LAIKA_MODAL_ROOT.
   */
  modalRoot: PropTypes.string,
  /** Fires when the user attempts to close the modal (i.e click close/outside, Escape button). */
  onClose: PropTypes.func.isRequired,
  /** Optional additional footer button. */
  onSecondaryAction: PropTypes.func,
  /** Fires when the user accepts whatever changes they have applied in the modal. */
  onSubmit: PropTypes.func,
  /** Disabled optional secondary footer action. */
  secondaryActionDisabled: PropTypes.bool,
  /** Mark optional secondary footer action as loading. */
  secondaryActionLoading: PropTypes.bool,
  /** Button text of optional secondary footer action. */
  secondaryActionText: PropTypes.string,
  /** Controls if the primary button in the footer is disabled. */
  submitDisabled: PropTypes.bool,
  /** Controls if the primary button in the footer is loading. */
  submitLoading: PropTypes.bool,
  /** Text that appears in the primary button in the footer. */
  submitText: PropTypes.string,
  /** Title of the modal that appears in the header. */
  title: PropTypes.string,
  /**
   * Standard modals have footers.
   * Dialog modals fit to content and have no footer.
   * SlideRight modals take up the full viewport height and slide in from the right.
   */
  variant: PropTypes.oneOf(['standard', 'dialog', 'slideRight']),
}

const MODAL_TIMING = Number(styles.modalTiming)
const MODAL_ROOT = process.env.REACT_APP_LAIKA_MODAL_ROOT ?? 'modal-root'

function Modal({
  children,
  closeText = 'Cancel',
  closeDisabled = false,
  isOpen,
  modalRoot = MODAL_ROOT,
  onClose,
  onSecondaryAction,
  onSubmit,
  secondaryActionDisabled = false,
  secondaryActionLoading = false,
  secondaryActionText,
  submitDisabled = false,
  submitLoading = false,
  submitText = 'Submit',
  title,
  variant = 'standard',
}) {
  const escapeCloseRef = useRef()
  const overlayElRef = useRef()
  const modalRootElRef = useRef(document.getElementById(modalRoot))
  const containerElRef = useRef(document.createElement('div'))

  useEffect(() => {
    const modalRootEl = modalRootElRef.current
    const containerEl = containerElRef.current

    if (modalRootEl.parentNode !== containerEl) {
      modalRootEl.appendChild(containerEl)
    }

    return () => {
      modalRootEl.removeChild(containerEl)
    }
  }, [containerElRef, modalRootElRef])

  useEffect(() => {
    if (!escapeCloseRef.current) {
      escapeCloseRef.current = event => {
        if (isOpen && onEscapeKey(event)) {
          onClose()
        }
      }
    }

    if (isOpen) {
      document.addEventListener('keydown', escapeCloseRef.current)
    }

    return () => {
      document.removeEventListener('keydown', escapeCloseRef.current)
    }
  }, [isOpen, onClose])

  return createPortal(
    <CSSTransition
      in={isOpen}
      timeout={MODAL_TIMING}
      classNames={{
        enter: styles.modalEnter,
        enterActive: styles.modalEnterActive,
        enterDone: styles.modalEnterDone,
        exit: styles.modalExit,
        exitActive: styles.modalExitActive,
        exitDone: styles.modalExitDone,
      }}
      unmountOnExit
    >
      <div
        ref={overlayElRef}
        role="presentation"
        className={cs(styles.modalOverlay, { [styles.slideRight]: variant === 'slideRight' })}
        onClick={event => {
          if (event.target === overlayElRef.current && !closeDisabled) {
            onClose()
          }
        }}
      >
        <div
          role="dialog"
          aria-labelledby="kh-modal-title"
          className={cs(styles.modal, styles[variant])}
        >
          <header className={styles.header}>
            <span id="kh-modal-title" className={styles.title}>
              {title}
            </span>
            <IconButton
              icon="close"
              disabled={closeDisabled}
              callback={onClose}
            />
          </header>
          <div className={styles.content}>
            {children}
          </div>
          {variant !== 'dialog' && (
            <footer className={styles.footer}>
              <Button
                variant="text"
                disabled={closeDisabled}
                onClick={onClose}
              >
                {closeText}
              </Button>
              {(onSecondaryAction && secondaryActionText) && (
                <Button
                  variant="secondary"
                  onClick={onSecondaryAction}
                  loading={secondaryActionLoading}
                  disabled={secondaryActionDisabled}
                >
                  {secondaryActionText}
                </Button>
              )}
              <Button
                variant="primary"
                onClick={onSubmit}
                loading={submitLoading}
                disabled={submitDisabled}
              >
                {submitText}
              </Button>
            </footer>
          )}
        </div>
      </div>
    </CSSTransition>,
    modalRootElRef.current
  )
}

Modal.propTypes = propTypes

export default Modal

import { useEffect } from 'react'

/** Given a ref to an <input> element, pass down a custom error message to mark
 * the value of the input as invalid.
 *
 * This message does not appear to users normally, but it does get announced by
 * screen readers. Therefore it's important to show the error message elsewhere
 * in the UI surface and how the user can provide us valid input.
 *
 * @param inputRef {RefObject} - React ref to an input element
 * @param errorMessage {String|null} - Error message to trigger invalidity
 * @param report {boolean} - Tell the browser to show a tooltip with message content
 */
export default function useCustomValidity(inputRef, errorMessage = '', report = false) {
  useEffect(() => {
    inputRef.current.setCustomValidity(errorMessage ?? '')
    if (report) inputRef.current.reportValidity()
  }, [inputRef, errorMessage, report])
}

import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import Icon from '../Icon'
import LoadingSpinner from './LoadingSpinner'
import * as styles from './Button.module.scss'

const propTypes = {
  /** Interior text of the button */
  children: PropTypes.string.isRequired,
  /** Keeps user from interacting with the button. */
  disabled: PropTypes.bool,
  /** ID of a form that this button controls. */
  form: PropTypes.string,
  /** Optional icon to put in front of the interior button text. */
  icon: PropTypes.string,
  /** Sets a visual loading state on the button. Replaces icons if present. */
  loading: PropTypes.bool,
  /** Callback triggered when user interacts with button via click or keyboard. Event is passed as first argument. */
  onClick: PropTypes.func.isRequired,
  /** Controls the size of the button. */
  size: PropTypes.oneOf(['large', 'small']),
  /** Type of button. Impacts screen readers and form behavior. */
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  /** Visual style of the button. */
  variant: PropTypes.oneOf(['primary', 'secondary', 'text', 'error']),
}

/**
 * The bread-and-butter UI control element.
 *
 * Supports ref-forwarding for complex use cases.
 */
const Button = React.forwardRef(
  (
    {
      children,
      disabled = false,
      icon,
      form,
      loading,
      onClick,
      size = 'large',
      type = 'button',
      variant = 'primary',
    },
    ref
  ) => (
    <button
      ref={ref}
      type={type}
      form={form}
      className={classnames(styles.button, styles[size], styles[variant])}
      disabled={disabled}
      onClick={onClick}
    >
      {Boolean(icon) && !loading && (
        <Icon icon={icon} color="inherit" size={size === 'large' ? 'medium' : 'small'} />
      )}
      {loading && <LoadingSpinner disabled={disabled} size={size} variant={variant} />}
      <span>
        {children}
      </span>
    </button>
  )
)

Button.displayName = 'Button'
Button.propTypes = propTypes

export default Button
